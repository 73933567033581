:root {
  --toastify-color-success: #33DFA1;
  --toastify-color-error: #f44336;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.pac-container {
  z-index: 9999;
}

/* tostify box */
.Toastify__toast {
  border-radius: 10px;
  min-height: 55px;
}

.Toastify__toast-container--top-center {
  top: 70px;
}

.Toastify__close-button {
  align-self: unset;
}

.Toastify__close-button svg {
  height: 20px;
  width: 20px;
}

.Toastify__toast-container--top-center {
  width: 80%;
  max-width: 470px;
  left: 50%;
  transform: translateX(-50%);
}